<template>
  <div
    v-if="isSearchBoxOpen"
    class="absolute left-0 top-0 z-10 h-full w-full bg-white"
  >
    <div class="relative w-full py-2 lg:mx-0 lg:w-auto">
      <div>
        <div class="relative flex justify-center">
          <div class="mx-2 flex flex-shrink-0 items-center">
            <IconClose
              class="text-xl text-red-500"
              @click.prevent="isSearchBoxOpen = false"
            />
          </div>
          <form class="flex w-full">
            <input
              v-model="query"
              type="search"
              :placeholder="t('searchRestaurantPlaceHolder')"
              name="search"
              class="searchbox-input truncate"
              required
            />
            <input type="submit" class="searchbox-submit rounded-full" />
            <IconSearch
              class="searchbox-icon inline h-[25px] w-[25px] cursor-pointer rounded-full bg-red-500 p-1 font-bold text-white"
            />
          </form>
        </div>
      </div>
    </div>
  </div>
  <IconSearch
    class="inline h-[25px] w-[25px] cursor-pointer rounded-full bg-red-500 p-1 font-bold text-white"
    @click.prevent="isSearchBoxOpen = !isSearchBoxOpen"
  />
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n";
import { ref } from "#imports";
import IconSearch from "~icons/hh-icons/icon-search";
import IconClose from "~icons/hh-icons/icon-times";
const { t } = useI18n({
  useScope: "global",
});

const query = ref("");
const isSearchBoxOpen = ref(false);
</script>

<script lang="ts">
export default {
  name: "SearchMobile",
};
</script>

<style lang="scss" scoped>
.searchbox-input {
  top: 0;
  right: 0;
  border: 0;
  outline: 0;
  width: 100%;
  height: 30px;
  margin: 0;
  padding: 0 40px 0 0;
  border-radius: 25px;
}

.searchbox-input::-webkit-input-placeholder {
  @apply text-gray-500;
}

.searchbox-input:-moz-placeholder {
  @apply text-gray-500;
}

.searchbox-input::-moz-placeholder {
  @apply text-gray-500;
}

.searchbox-input:-ms-input-placeholder {
  @apply text-gray-500;
}
.searchbox-icon,
.searchbox-submit {
  width: 30px;
  height: 30px;
  display: block;
  position: absolute;
  top: 0;

  /* font-size: 22px; */
  right: 0;
  margin: 0;
  border: 0;
  outline: 0;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
  color: #fff;
}
</style>
